import React from 'react';
import PropTypes from 'prop-types';

// UI
// =============================================================================
import Container from 'common/src/components/UI/Container';

// Components
// =============================================================================
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Image from 'common/src/components/Image';
import Button from 'common/src/components/Button';

// Styles
// =============================================================================
import SectionAreaWrapper, { SectionArea } from './ThankYou.style';

// Colours
// =============================================================================
import brand from 'common/src/theme/landing/brand';

// Images
// =============================================================================
import logo from 'common/src/assets/image/logo.png';

// ThankYou
// =============================================================================
const ThankYou = ({
  SectionWrapperStyle,
  RowStyle,
  ColStyle,
  ThankYouImageStyle,
  ThankYouHeadingStyle,
  ThankYouTextStyle,
  ThankYouVisitButtonWrapperStyle,
  ThankYouVisitButtonStyle,
}) => {
  const handleOpenWebsite = () => {
    window.open('https://cybertonica.com?ref=thank-you-discover', '_blank');
  };

  return (
    <Box {...SectionWrapperStyle} id="intro_image">
      <Container>
        <Box {...RowStyle}>
          <Box {...ColStyle}>
            <SectionAreaWrapper>
              <SectionArea>
                <Image src={logo} {...ThankYouImageStyle} alt="Logo" />
                <Heading {...ThankYouHeadingStyle} content="Thank You!" />
                <Text {...ThankYouTextStyle} content="for subscribing" />
                <Box {...ThankYouVisitButtonWrapperStyle}>
                  <Button
                    title="Visit our Website"
                    onClick={handleOpenWebsite}
                    {...ThankYouVisitButtonStyle}
                  />
                </Box>
              </SectionArea>
            </SectionAreaWrapper>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

ThankYou.propTypes = {
  SectionWrapperStyle: PropTypes.object,
  RowStyle: PropTypes.object,
  ColStyle: PropTypes.object,
  ThankYouImageStyle: PropTypes.object,
  ThankYouHeadingStyle: PropTypes.object,
  ThankYouTextStyle: PropTypes.object,
  ThankYouVisitButtonWrapperStyle: PropTypes.object,
  ThankYouVisitButtonStyle: PropTypes.object,
};

ThankYou.defaultProps = {
  SectionWrapperStyle: {
    as: 'section',
    bg: brand.brand8,
    pt: ['20px', '20px', '30px', '30px', '40px'],
    pb: ['40px', '40px', '50px', '50px', '60px'],
  },
  RowStyle: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    height: ['auto', 'auto', '400px', '430px', '460px'],
  },
  ColStyle: {
    width: '100%',
  },
  ThankYouImageStyle: {
    maxWidth: '100px',
    width: '100%',
    m: '0 auto 40px',
  },
  ThankYouHeadingStyle: {
    fontSize: ['26px', '30px', '34px', '36px', '40px'],
    fontWeight: '500',
    color: '#fff',
    letterSpacing: '0em',
    mb: '20px',
    lineHeight: '1.35',
    textAlign: 'center',
    maxWidth: '810px',
    ml: 'auto',
    mr: 'auto',
  },
  ThankYouTextStyle: {
    fontSize: ['18px', '18px', '19px', '19px', '20px'],
    color: '#fff',
    lineHeight: '1.6',
    textAlign: 'center',
    mb: ['30px'],
  },
  ThankYouVisitButtonWrapperStyle: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ThankYouVisitButtonStyle: {
    type: 'button',
    fontSize: ['17px', '18px'],
    fontWeight: '500',
    colors: 'cyber1WithBg',
    minHeight: '44px',
    pt: '0',
    pb: '0',
    pl: ['15px', '30px'],
    pr: ['15px', '30px'],
  },
};

export default ThankYou;
