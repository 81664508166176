import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import { landingTheme } from 'common/src/theme/landing';
import { ResetCSS } from 'common/src/assets/css/style';
import { useStaticQuery, graphql } from 'gatsby';
import CookieConsent from 'react-cookie-consent';

// Sections
import Header from '../containers/Landing/sections/Header';
import ThankYou from '../containers/Landing/sections/ThankYou';
// import ThankYouVisit from '../containers/Landing/sections/ThankYouVisit';
import StaticFooter from '../containers/Landing/sections/StaticFooter';

// Styles
import {
  GlobalStyle,
  ContentWrapper,
} from '../containers/Landing/landing.style';

// Components
import SEO from '../components/seo';

export default () => {
  const DataQuery = useStaticQuery(graphql`
    query {
      eComm360ShopifyV1Json {
        A {
          StaticFooter {
            footerAbout
            dataAwards {
              content
            }
          }
        }
      }
    }
  `);

  // Data
  const Data = DataQuery.eComm360ShopifyV1Json.A;

  // StaticFooter
  const footerAbout = Data.StaticFooter.footerAbout;
  const dataAwards = Data.StaticFooter.dataAwards;

  return (
    <ThemeProvider theme={landingTheme}>
      <Fragment>
        <SEO title="Thank You" description="" />
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Header />
          <ThankYou />
          {/* <ThankYouVisit /> */}
          <StaticFooter footerAbout={footerAbout} dataAwards={dataAwards} />
          <CookieConsent
            location="bottom"
            buttonText="Accept"
            style={{ background: '#2b3d4d' }}
            buttonStyle={{
              color: '#2b3d4d',
              background: '#e6ff0f',
              fontSize: '13px',
              borderRadius: '4px',
            }}
            cookieName="gatsby-plugin-gdpr-cookies"
          >
            We are using cookies to give you the best experience on our website.
            <br />
            You can find out more about which cookies we are using or switch
            them off in settings. To find out more, see our
            <a
              href="https://cybertonica.com/privacy-policy?ref=discover"
              className="DefaultPrivacyLink"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            .
          </CookieConsent>
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
